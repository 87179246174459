import Aside from "../../components/_layout/aside";
import Page from "../../components/_layout/page";
import Responsive from "../../components/_layout/responsive";
import Proposal from "../../components/_proposals/master";
import Grid from "../../components/_grid/grid";

import { proposals } from "../../constants/proposalApi.js";

const ProposalMain = () => {
  const renderProposals = () => {
    return proposals.map((p, i) => {
      return (
        <Proposal
          key={i}
          route={`/governance/${p.title}`}
          title={p.title}
          description={p.description}
          category={p.category}
          subCategory={p.subCategory}
        />
      );
    });
  };
  return (
    <Responsive>
      <Aside />
      <Page title="Proposals">
        <Grid rows="1">{renderProposals()}</Grid>
      </Page>
    </Responsive>
  );
};

export default ProposalMain;
