import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: ${(props) => props.theme.layout.default.gap};

  &:hover {
    cursor: pointer;
  }
`;

export const Image = styled.div`
  padding-bottom: 100%;
  border-radius: ${(props) => props.theme.layout.default.radius};
  background: ${(props) => props.theme.placeholder};
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
