// Relative Imports
import { Container, Button } from "./styles";
import { H5 } from "../../../constants/type.js";
import Cell from "../../cell";

const Vote = ({ onClick }) => {
  return (
    <Container>
      <H5 mb="12px">Voting</H5>
      <Cell key={"1"} name="Open" value="Dec 1, 2022" />
      <Cell key={"2"} name="Closed" value="Dec 31, 2022" />
      <Cell key={"3"} name="Points" value="250" />
      <Cell key={"4"} name="Votes" value="12" />
      <Cell key={"5"} name="Pass Threshold" value="16" />
      <Cell key={"6"} name="Rejection Threshold" value="12" />
      <Button onClick={onClick}>Cast Vote</Button>
    </Container>
  );
};

export default Vote;
