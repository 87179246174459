import styled from "styled-components";
import media from "../../../constants/media.js";
import { NavLink } from "react-router-dom";

const isActive = "selected";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const List = styled.div`
  height: auto;

  ${media.md`
      width: 100vw;
      padding-top: 0px;
      display: flex;
      flex-direction: row;
  `};
`;

export const Item = styled.p`
  font-family: ${(props) => props.theme.layout.fontBody};
  font-size: 16px;
  line-height: 1.5em;
  margin: 0px;
  color: ${(props) => props.theme.primary};

  margin-top: ${(props) => props.mt};
  margin-bottom: ${(props) => props.mb};
  margin-left: ${(props) => props.ml};
  margin-right: ${(props) => props.mr};

  ${media.md`
    font-size: 12px;
  `};
`;

export const Route = styled(NavLink)`
  padding: 16px 20px;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: 250ms;
  border-top: 1px solid ${(props) => props.theme.border};
  opacity: 75%;

  &:last-child {
    border-bottom: 1px solid ${(props) => props.theme.border};
  }

  &:hover {
    transition: 250ms;
    cursor: pointer;
    background: ${(props) => props.theme.variants.grey[2].hex};
    border-left: 5px solid ${(props) => props.theme.variants.accent[10].hex};
  }

  &.active {
    opacity: 100%;
    border-left: 5px solid ${(props) => props.theme.variants.accent[10].hex};
  }

  ${media.md`
      display: flex;
      flex-direction: column;
      width: 25%;
      justify-content: center;

      &.active {
        border: none;
      }
  `};
`;

export const Auth = styled.div`
  height: 48px;
  font-family: "Inter", sans-serif;
  background: ${(props) => props.theme.variants.accent[10].hex};
  color: white;
  margin: 20px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  transition: 750ms;
  text-decoration: none;
  margin-bottom: 320px;

  &:hover {
    cursor: pointer;
    background: #cc1d01;
    transition: 750ms;
  }
`;

export const Icon = styled.img`
  height: 32px;
  width: 32px;
  margin-right: 20px;

  ${media.md`
      margin-right: 0px;
      margin-bottom: 8px;
  `};
`;
