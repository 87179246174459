import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  body {
    padding: 0;
    margin: 0;
    border: 0;
    background: "#fafafa";
    font-family: 'Inter', sans-serif;
    font-family: 'Secular One', sans-serif;
    font-feature-settings: 'zero' 1;
    font-size: 16px;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-display: swap;
}


  `;
