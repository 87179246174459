// Relative Imports
import { Container, Item, Icon, Column } from "./styles";
import { Title, H5, H6, P } from "../../../constants/type.js";

const Outline = ({ onClick }) => {
  return (
    <Container>
      <H5 fontSize={"20px"} mb="12px">
        INTRODUCTION TASKS
      </H5>
      <Item onClick={onClick}>
        <Icon />
        <Column>
          <H6 uppercase>Mint Asset</H6>
          <P>Mint your NFT</P>
        </Column>
      </Item>
      <Item>
        <Icon />
        <Column>
          <H6 uppercase>Join Discord</H6>
          <P>Join the community</P>
        </Column>
      </Item>
      <Item>
        <Icon />
        <Column>
          <H6 uppercase>Forum Registration</H6>
          <P>Register to the forum</P>
        </Column>
      </Item>
      <Item>
        <Icon />
        <Column>
          <H6 uppercase>Complete Profile</H6>
          <P>Complete your profile</P>
        </Column>
      </Item>
      <Item>
        <Icon />
        <Column>
          <H6 uppercase>Follow Twitter</H6>
          <P>Follow G7</P>
        </Column>
      </Item>
      <Item>
        <Icon />
        <Column>
          <H6 uppercase>Introductory Post</H6>
          <P>Join the discord and get started</P>
        </Column>
      </Item>
      <Item>
        <Icon />
        <Column>
          <H6 uppercase>Introductory Post</H6>
          <P>Join the discord and get started</P>
        </Column>
      </Item>
      <Item>
        <Icon />
        <Column>
          <H6 uppercase>Introductory Post</H6>
          <P>Join the discord and get started</P>
        </Column>
      </Item>
    </Container>
  );
};

export default Outline;
