import Aside from "../../components/_layout/aside";
import Page from "../../components/_layout/page";
import Responsive from "../../components/_layout/responsive";
import Grid from "../../components/_grid/grid";
import Statistic from "../../components/_rewards/statistic";
import Reward from "../../components/_rewards/reward";

import { H4, P } from "../../constants/type.js";

const RewardsMain = () => {
  return (
    <Responsive>
      <Aside />
      <Page title="Rewards">
        <>
          <H4 mb="20px">Overview</H4>
          <P mt="-12px" mb="20px">
            Overview of your current rewards
          </P>
        </>
        <Grid rows="3">
          <Statistic title="12" label="Level" />
          <Statistic title="1482" label="Points" />
          <Statistic title="3" label="Rewards" />
        </Grid>
        <>
          <H4 mb="20px" mt="20px">
            Redeem
          </H4>
          <P mt="-12px" mb="20px">
            Discover rewards that you can redeem for rewards
          </P>
        </>
        <Grid rows="2">
          <Reward name="First" />
          <Reward name="Second" />
          <Reward name="Third" />
          <Reward name="Fourth" />
        </Grid>
      </Page>
    </Responsive>
  );
};

export default RewardsMain;
