export const avatars = [
  {
    title: "Name",
    number: "100",
    src: "",
    // Avatar Stats
    points: "points",
    rank: "rank",
    status: "status",
    access: "access",
  },
  {
    title: "Name",
    number: "100",
    src: "",
    // Avatar Stats
    points: "points",
    rank: "rank",
    status: "status",
    access: "access",
  },
  {
    title: "Name",
    number: "100",
    src: "",
    // Avatar Stats
    points: "points",
    rank: "rank",
    status: "status",
    access: "access",
  },
  {
    title: "Name",
    number: "100",
    src: "",
    // Avatar Stats
    points: "points",
    rank: "rank",
    status: "status",
    access: "access",
  },
  {
    title: "Name",
    number: "100",
    src: "",
    // Avatar Stats
    points: "points",
    rank: "rank",
    status: "status",
    access: "access",
  },
  {
    title: "Name",
    number: "100",
    src: "",
    // Avatar Stats
    points: "points",
    rank: "rank",
    status: "status",
    access: "access",
  },
  {
    title: "Name",
    number: "100",
    src: "",
    // Avatar Stats
    points: "points",
    rank: "rank",
    status: "status",
    access: "access",
  },
  {
    title: "Name",
    number: "100",
    src: "",
    // Avatar Stats
    points: "points",
    rank: "rank",
    status: "status",
    access: "access",
  },
  {
    title: "Name",
    number: "100",
    src: "",
    // Avatar Stats
    points: "points",
    rank: "rank",
    status: "status",
    access: "access",
  },
];
