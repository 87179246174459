// Relative Imports
import { Container } from "./styles";
import { H5 } from "../../../constants/type.js";

const Reward = ({ name }) => {
  return (
    <Container>
      <H5>{name}</H5>
    </Container>
  );
};

export default Reward;
