// Relative Imports
import { Container } from "./styles";
import { P } from "../../constants/type.js";

const Cell = ({ index, name, value }) => {
  return (
    <Container key={index}>
      <P>{name}</P>
      <P color="primary">{value}</P>
    </Container>
  );
};

export default Cell;
