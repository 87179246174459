// Imports
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import {
  DynamicContextProvider,
  useDynamicContext,
} from "@dynamic-labs/sdk-react";
// Pages
import Home from "./pages/Home.js";
import Dashboard from "./pages/Dashboard/Dashboard.js";
import Parent from "./pages/Quests/Parent.js";
import RewardsMain from "./pages/Rewards/RewardsMain.js";
import Detail from "./pages/Quests/Detail.js";
import Master from "./pages/Quests/Master.js";
import Profile from "./pages/Profile/Profile.js";
import Gallery from "./pages/Gallery/Gallery.js";
import ProposalMain from "./pages/Proposals/ProposalMain.js";
import ProposalDetail from "./pages/Proposals/ProposalDetail.js";
import QuestRoutes from "./routes/quests.js";
import Onboarding from "./pages/Onboarding/Onboarding.js";

// Theme
import { light } from "./constants/themes.js";
function App() {
  // console.log("HERO", user === undefined ? "Logged Out" : "Logged In");

  return (
    <DynamicContextProvider
      settings={{
        appLogoUrl:
          "https://upload.wikimedia.org/wikipedia/commons/3/34/Examplelogo.svg",
        appName: "Quests",
        environmentId: "16fc0090-7f07-4cfc-ae2a-5dcac3da79dd",
      }}
    >
      <ThemeProvider theme={light}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/onboarding" element={<Onboarding />} />
            <Route path="/overview" element={<Dashboard />} />
            <Route path="/quests" element={<Parent />} />
            <Route path="/quests/:category" element={<Master />} />
            <Route
              path="/quests/:category/:subCategory/:level"
              element={<Detail />}
            />
            <Route path="/rewards" element={<RewardsMain />} />
            <Route path="/governance" element={<ProposalMain />} />
            <Route path="/governance/:proposal" element={<ProposalDetail />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/gallery" element={<Gallery />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </DynamicContextProvider>
  );
}

export default App;
