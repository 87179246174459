// Relative Imports
import { Container, Field, Label, Error, Row } from "./styles";

const Input = ({ label, placeholder, error, onChange, ...rest }) => {
  return (
    <Container>
      <Row>
        <Label>{label}</Label>
        <Error>{error}</Error>
      </Row>
      <Field onChange={onChange} placeholder={placeholder} {...rest} />
    </Container>
  );
};

export default Input;
