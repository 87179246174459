// Relative Imports
import { Container, Column, Route, Disabled } from "./styles";
import { H5 } from "../../../constants/type.js";

const Level = ({ route, title, description, disabled }) => {
  return (
    <Container>
      {disabled ? (
        <>
          <Route to={route}>
            <Column>
              <H5 mb="8px">{title}</H5>
            </Column>
          </Route>
        </>
      ) : (
        <Disabled disabled={true}>
          <Column>
            <H5 mb="8px">{title}</H5>
          </Column>
        </Disabled>
      )}
    </Container>
  );
};

export default Level;
