import { useState } from "react";

import { Container, Field, Label, Error, Row, Cell, Button } from "./styles";

const Input = ({ label, placeholder, error, onChange, ...rest }) => {
  const [display, setDisplay] = useState(false);
  const [active, setActive] = useState(false);

  return (
    <Container>
      <Row>
        <Label>{label}</Label>
        {display && (
          <>
            <Error>{"100+"}</Error>
          </>
        )}
      </Row>
      <Cell
        onMouseOver={() => setActive(true)}
        onMouseLeave={() => setActive(false)}
        active={active}
      >
        <Field onChange={onChange} placeholder={placeholder} {...rest} />
        {active && (
          <Button onClick={() => setDisplay(!display)}>Connect</Button>
        )}
      </Cell>
    </Container>
  );
};

export default Input;
