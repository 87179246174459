import {
  useDynamicContext,
  DynamicContextProvider,
  DynamicWidget,
} from "@dynamic-labs/sdk-react";

import { Container, Brand, Icon, Name, Background, Join, Auth } from "./styles";
import logo from "../../../assets/brand/logo.svg";
import icon from "../../../assets/brand/icon.svg";

const Navigation = ({ hide }) => {
  const {
    user,
    handleLogOut,
    setShowAuthFlow,
    showAuthFlow,
  } = useDynamicContext();

  const handleSession = () => {
    handleLogOut();
    if (user && !showAuthFlow) {
      window.location.assign("/");
    }
  };

  return (
    <Container>
      <Brand to="/">
        <Background>
          <Icon src={icon} />
        </Background>
        <Name src={logo} />
      </Brand>
      {user && !showAuthFlow && (
        <Auth onClick={() => handleSession()}>Log Out</Auth>
      )}
    </Container>
  );
};

export default Navigation;
