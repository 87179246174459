// Relative Imports
import { Container, Column, Row } from "./styles";
import Pill from "../pill";

import { H5, P, Custom } from "../../../constants/type.js";

const Proposal = ({
  key,
  title,
  description,
  category,
  route,
  subCategory,
}) => {
  return (
    <Container key={key} to={route}>
      <Column>
        <H5>{title}</H5>
        <Row>
          <Pill type={"category"}>{category}</Pill>
          <Pill type={"subCategory"}>{subCategory}</Pill>
        </Row>
        <P>{description}</P>
      </Column>
    </Container>
  );
};

export default Proposal;
