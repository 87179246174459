// Relative Imports
import { Category, SubCategory } from "./styles";

const Pill = ({ type, children }) => {
  return (
    <>
      {type === "category" && <Category>{children}</Category>}
      {type === "subCategory" && <SubCategory>{children}</SubCategory>}
    </>
  );
};

export default Pill;
