// Relative Imports
import { Container } from "./styles";
import { P, H4 } from "../../../constants/type.js";

const Statistic = ({ title, label }) => {
  return (
    <Container>
      <H4>{title}</H4>
      <P mt="4px">{label}</P>
    </Container>
  );
};

export default Statistic;
