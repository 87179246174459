// Relative Imports
import { Container, Button } from "./styles";
import { H5 } from "../../../constants/type.js";
import Cell from "../../cell";
import Avatar from "../../avatar/index.js";

const Account = ({ onClick }) => {
  return (
    <Container>
      <Avatar />
      <Cell name="Name" value="Jon Allen" />
      <Cell name="Email" value="jon@hotmail.com" />
      <Cell name="Twitter" value="@jonallen" />
      <Cell name="Wallet" value="0x1234...4321" />
    </Container>
  );
};

export default Account;
