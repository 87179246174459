import styled from "styled-components";
import media from "../../../constants/media.js";

export const Container = styled.div`
  height: 100vh;
  min-width: 280px;
  background: ${(props) => props.theme.background};

  ${media.md`
    height: 80px;
    width: 100%;
    bottom: 0px;
    position: fixed;
  `};
`;

export const Image = styled.div`
  height: 0;
  padding-bottom: 100%;
  border-radius: 8px;
  background: ${(props) => props.theme.placeholder};
  border: 10px solid ${(props) => props.theme.variants.grey[0].hex};
`;

export const Avatar = styled.div`
  padding: 20px;

  ${media.md`
    display: none;
  `};

  &:hover {
    cursor: pointer;
  }
`;
