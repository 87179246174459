import styled from "styled-components";
import media from "../../../constants/media.js";

export const Container = styled.div`
  display: grid;
  flex-direction: row;
  height: max-content;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: -1px;
  box-shadow: ${(props) => props.theme.layout.shadow};

  background: ${(props) => props.theme.foreground};
  border: 1px solid ${(props) => props.theme.border};
  border-radius: 0px 0px ${(props) => props.theme.layout.default.radius}
    ${(props) => props.theme.layout.default.radius};

  ${media.md`
   grid-template-columns: 1fr 1fr;
   `};

  ${media.sm`
   grid-template-columns: 1fr ;
   `};
`;

export const Item = styled.div`
  justify-content: flex-start;
  display: flex;
  padding: ${(props) => props.theme.layout.default.spacing};
`;

export const Icon = styled.div`
  height: 48px;
  width: 48px;
  border-radius: 12px;
  margin-right: 16px;
  background: ${(props) => props.theme.variants.purple[2].hex};
`;

export const Column = styled.div`
  display: flex;
  grid-gap: 4px;
  flex-direction: column;
  justify-content: center;
`;
