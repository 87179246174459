// Relative Imports
import { Container, Column, Route, Disabled, Icon } from "./styles";
import { H5, P } from "../../../constants/type.js";

const Task = ({ route, title, description, disabled }) => {
  return (
    <Container>
      {disabled ? (
        <>
          <Route to={route}>
            <Icon />
            <Column>
              <H5 mb="8px">{title}</H5>
              <P>{description}</P>
            </Column>
          </Route>
        </>
      ) : (
        <Disabled disabled={true}>
          <Icon />
          <Column>
            <H5 mb="8px">{title}</H5>
            <P>{description}</P>
          </Column>
        </Disabled>
      )}
    </Container>
  );
};

export default Task;
