export const tasks = [
  {
    title: "Level One",
    description:
      "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    url: "https://game7.io/",
    points: "100",
    progress: "1.0",
    category: "grants",
    subCategory: "technology",
    level: "introduction",
    route: "task-name",
    accessAllowed: true,
  },
  {
    title: "Level Two",
    description:
      "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    url: "https://game7.io/",
    points: "100",
    progress: "1.0",
    category: "grants",
    subCategory: "events",
    level: "introduction",
    route: "task-name",
    accessAllowed: false,
  },
  {
    title: "Level Three",
    description:
      "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    url: "https://game7.io/",
    points: "100",
    progress: "1.0",
    category: "grants",
    subCategory: "research",
    level: "introduction",
    route: "task-name",
    accessAllowed: false,
  },
  {
    title: "Level Four",
    description:
      "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    url: "https://game7.io/",
    points: "100",
    progress: "1.0",
    category: "grants",
    subCategory: "education",
    level: "introduction",
    route: "task-name",
    accessAllowed: false,
  },
  {
    title: "Level Five",
    description:
      "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    url: "https://game7.io/",
    points: "100",
    progress: "1.0",
    category: "grants",
    subCategory: "diversity",
    level: "introduction",
    route: "task-name",
    accessAllowed: false,
  },
  {
    title: "Level Six",
    description:
      "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    url: "https://game7.io/",
    points: "100",
    progress: "1.0",
    category: "investments",
    subCategory: "infrastructure",
    level: "introduction",
    route: "task-name",
    accessAllowed: false,
  },
  {
    title: "Level Seven",
    description:
      "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    url: "https://game7.io/",
    points: "100",
    progress: "1.0",
    category: "investments",
    subCategory: "distribution",
    level: "introduction",
    route: "task-name",
    accessAllowed: false,
  },
  {
    title: "Level Seven",
    description:
      "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    url: "https://game7.io/",
    points: "100",
    progress: "1.0",
    category: "investments",
    subCategory: "content",
    level: "introduction",
    route: "task-name",
    accessAllowed: false,
  },
];
