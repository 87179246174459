import Aside from "../../components/_layout/aside";
import Page from "../../components/_layout/page";
import Responsive from "../../components/_layout/responsive";
import Point from "../../components/_forms/point";
import Input from "../../components/_forms/input";
import Single from "../../components/_grid/single";
import Double from "../../components/_grid/double";
import Account from "../../components/_proposals/profile/index.js";

const Profile = () => {
  return (
    <Responsive>
      <Aside />
      <Page title="Profile">
        <Double>
          <div>
            <Input label="Name" placeholder="Enter name" />
            <Input label="Email" placeholder="Enter email" />
            <Input label="Twitter" placeholder="Enter twitter" />
            <Point label="Wallet" placeholder="Enter address" />
          </div>
          <Account />
        </Double>
      </Page>
    </Responsive>
  );
};

export default Profile;
