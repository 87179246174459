import styled from "styled-components";
import media from "../../../constants/media.js";
import { Link } from "react-router-dom";

export const Container = styled.div`
  flex-grow: 1;
  border-radius: 24px 0px 0px 24px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 10px 8px 7px rgba(194, 211, 226, 0.1);
  z-index: 5000;
  background: ${(props) => props.theme.foreground};
  border: 1px solid ${(props) => props.theme.border};

  ${media.md`
    height: calc(100vh - 80px);
    border-radius: 24px 24px 0px 0px;
  `};
`;

export const Header = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.border};
`;

export const Content = styled.div`
  padding: 20px;
  overflow: scroll;
`;

export const Row = styled.div`
  display: flex;
  grid-gap: 20px;
  align-items: center;
`;

export const Gallery = styled(Link)`
  height: 48px;
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const Notifications = styled.img`
  height: 36px;
  width: 36px;
  cursor: pointer;
`;

export const Profile = styled(Link)`
  height: 48px;
  width: 48px;
  border-radius: 4px;
  background: ${(props) => props.theme.placeholder};
`;
