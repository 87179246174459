// Relative Imports
import {
  Container,
  Window,
  Row,
  Button,
  Column,
  Body,
  Image,
  Data,
  Header,
  Grid,
} from "./styles";
import { Title, H5, P } from "../../../constants/type.js";

const Modal = ({ onClick }) => {
  return (
    <Container>
      <Window>
        <Body>
          <Row>
            <Image />
            <Data>
              <div>
                <Header>
                  <Title
                    color={"primary"}
                    fontWeight={"bold"}
                    fontSize="32px"
                    textTransform="uppercase"
                  >
                    NFT #3212
                  </Title>
                  <P>@Username</P>
                </Header>
                <Grid>
                  <Column>
                    <H5>Level</H5>
                    <P>Placeholder</P>
                  </Column>
                  <Column>
                    <H5>Rank</H5>
                    <P>Placeholder</P>
                  </Column>
                  <Column>
                    <H5>Status</H5>
                    <P>Placeholder</P>
                  </Column>
                  <Column>
                    <H5>Points</H5>
                    <P>Placeholder</P>
                  </Column>
                  <Column>
                    <H5>Access</H5>
                    <P>Placeholder</P>
                  </Column>
                </Grid>
              </div>
              <Button onClick={onClick}>Close</Button>
            </Data>
          </Row>
        </Body>
      </Window>
    </Container>
  );
};

export default Modal;
