import styled from "styled-components";
import media from "../../../constants/media.js";

export const Container = styled.div`
  display: grid;
  grid-template-columns: 65% 1fr;
  grid-gap: 20px;
  align-items: flex-start;

  ${media.lg`
    display: flex;
    flex-direction: column;

  `};

  ${media.md`
    display: flex;
    flex-direction: column;
  `};

  ${media.sm`
    display: flex;
    flex-direction: column;
  `};
`;
