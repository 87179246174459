// Relative Imports
import {
  useDynamicContext,
  DynamicContextProvider,
  DynamicWidget,
} from "@dynamic-labs/sdk-react";
import { Container, Route, Icon, Item, List, Auth } from "./styles";

// Icons
import overview from "../../../assets/interface/overview.svg";
import quests from "../../../assets/interface/quests.svg";
import proposals from "../../../assets/interface/proposals.svg";
import rewards from "../../../assets/interface/rewards.svg";

const Menu = () => {
  const {
    user,
    handleLogOut,
    setShowAuthFlow,
    showAuthFlow,
  } = useDynamicContext();

  const handleSession = () => {
    handleLogOut();
    if (user && !showAuthFlow) {
      window.location.assign("/");
    }
  };

  console.log("APP", user);

  return (
    <Container>
      <List>
        <Route to="/overview">
          <Icon src={overview} />
          <Item>Overview</Item>
        </Route>
        <Route to="/quests">
          <Icon src={quests} />
          <Item>Quests</Item>
        </Route>
        <Route to="/governance">
          <Icon src={proposals} />
          <Item>Governance</Item>
        </Route>
        <Route to="/rewards">
          <Icon src={rewards} />
          <Item>Rewards</Item>
        </Route>
      </List>
      <Auth onClick={() => handleSession()}>Log Out</Auth>
    </Container>
  );
};

export default Menu;
