import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: ${(props) => props.border};
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  background: ${(props) => props.background};
  align-items: ${(props) => props.alignItems};
  justify-content: ${(props) => props.justifyContent};
  margin-top: ${(props) => props.marginTop};
  margin-bottom: ${(props) => props.marginBottom};
  margin-left: ${(props) => props.marginLeft};
  margin-right: ${(props) => props.marginRight};
  padding-top: ${(props) => props.paddingTop};
  padding-bottom: ${(props) => props.paddingBottom};
  padding-left: ${(props) => props.paddingLeft};
  padding-right: ${(props) => props.paddingRight};
`;
