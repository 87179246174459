import styled from "styled-components";

export const Category = styled.div`
  padding: 6px 12px;
  border-radius: 24px;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 700;
  background: ${(props) => props.theme.variants.purple[1].hex};
  color: ${(props) => props.theme.variants.purple[5].hex};
  border: 1px solid ${(props) => props.theme.variants.purple[2].hex};
`;

export const SubCategory = styled.div`
  padding: 6px 12px;
  border-radius: 24px;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 700;
  background: ${(props) => props.theme.variants.blue[1].hex};
  color: ${(props) => props.theme.variants.blue[5].hex};
  border: 1px solid ${(props) => props.theme.variants.blue[2].hex};
`;
