import { useState } from "react";
import { useParams } from "react-router-dom";
import Aside from "../../components/_layout/aside";
import Page from "../../components/_layout/page";
import Responsive from "../../components/_layout/responsive";
import Video from "../../components/_quests/video";
import Instructions from "../../components/_quests/instructions";
import Stats from "../../components/_quests/stats";
import Outline from "../../components/_quests/outline";
import Goals from "../../components/_quests/goals";
import Double from "../../components/_grid/double";
import Grid from "../../components/_grid/grid";
import Modal from "../../components/modal";

const Quests = () => {
  let { level, category, subCategory } = useParams();
  const [show, showModal] = useState(false);
  return (
    <>
      {show && <Modal onClick={() => showModal(false)} />}
      <Responsive>
        <Aside />
        <Page title={`${subCategory}: ${level}`}>
          <Double>
            <Grid row="1">
              <div>
                <Video />
                <Stats />
              </div>
              <Outline onClick={() => showModal(true)} />
            </Grid>
            <Grid row="1">
              <Goals />
              <Instructions />
            </Grid>
          </Double>
        </Page>
      </Responsive>
    </>
  );
};

export default Quests;
