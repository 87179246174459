import { useState } from "react";
import { useParams } from "react-router-dom";
import Aside from "../../components/_layout/aside";
import Page from "../../components/_layout/page";
import Responsive from "../../components/_layout/responsive";
import Double from "../../components/_grid/double";
import Information from "../../components/_proposals/information";
import Vote from "../../components/_proposals/vote";
import Grid from "../../components/_grid/grid/index.js";
import Modal from "../../components/_modals/proposalModal";

const ProposalDetail = () => {
  let { proposal } = useParams();
  const [show, toggleDisplay] = useState(false);
  return (
    <Responsive>
      {show && <Modal onClick={() => toggleDisplay(false)} />}
      <Aside />
      <Page title={proposal}>
        <Double>
          <Information />
          <Vote onClick={() => toggleDisplay(true)} data={proposal} />
        </Double>
      </Page>
    </Responsive>
  );
};

export default ProposalDetail;
