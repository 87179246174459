import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: fill-available;
  border-radius: ${(props) => props.theme.layout.default.radius};
  padding: ${(props) => props.theme.layout.default.spacing};
  background: ${(props) => props.theme.foreground};
  border: 1px solid ${(props) => props.theme.border};
  shadow: ${(props) => props.theme.shadow};
`;

export const Button = styled.button`
  height: 48px;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.variants.grey[0].hex};
  border: none;
  font-size: 16px;
  transition: 750ms;
  margin-top: 40px;
  visibility: ${(props) => (props.hidden ? "hidden" : "visible")};
  background: ${(props) => props.theme.variants.accent[10].hex};
  border-radius: ${(props) => props.theme.layout.default.radius};

  &:hover {
    cursor: pointer;
    transition: 750ms;
    background: ${(props) => props.theme.variants.accent[11].hex};
  }

  &:disabled {
    cursor: not-allowed;
    color: rgba(255, 255, 255, 0.6);
  }
`;
