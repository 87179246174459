import Aside from "../../components/_layout/aside";
import Page from "../../components/_layout/page";
import Responsive from "../../components/_layout/responsive";
import Grid from "../../components/_grid/grid";
import Statistic from "../../components/_rewards/statistic";
import Reward from "../../components/_rewards/reward";
import { H4, P } from "../../constants/type.js";

const Dashboard = () => {
  return (
    <Responsive>
      <Aside />
      <Page title="Overview">
        <>
          <H4 mb="20px">Accelerators</H4>
          <P mt="-12px" mb="20px">
            Discover ways to expand your point earnings
          </P>
        </>
        <Grid rows="3">
          <Statistic title="3" label="Daily Quests" />
          <Statistic title="7" label="Weekly Quests" />
          <Statistic title="12" label="Monthly Quests" />
        </Grid>
        <P mt="20px" mb="20px"></P>
        <Grid rows="2">
          <Reward name="Token Bonding" />
          <Reward name="Point Staking" />
        </Grid>
      </Page>
    </Responsive>
  );
};

export default Dashboard;
