import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: ${(props) => props.theme.layout.shadow};
  border-radius: ${(props) => props.theme.layout.default.radius};
  padding: ${(props) => props.theme.layout.default.padding};
  background: ${(props) => props.theme.foreground};
  border: 1px solid ${(props) => props.theme.border};
`;
