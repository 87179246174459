import {
  Container,
  Description,
  Content,
  Fill,
  Align,
  Section,
} from "./styles";
import { Title, Custom } from "../../constants/type.js";

const Hero = ({ message, onClick, connect }) => {
  return (
    <Container>
      <Content>
        <Title fontSize="120px" color="primary">
          Quests
        </Title>
        <Section>
          <Fill onClick={onClick}>Connect Wallet</Fill>
        </Section>
      </Content>
    </Container>
  );
};

export default Hero;
