import styled from "styled-components";
import media from "../../../constants/media.js";

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(${(props) => props.rows}, 1fr);
  grid-gap: 20px;

  ${media.lg`
    display: flex;
    flex-direction: column;
    width: 100%;
  `};
`;
