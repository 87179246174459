// Relative Imports
import { useState } from "react";
import {
  Container,
  Header,
  Content,
  Profile,
  Notifications,
  Gallery,
  Row,
} from "./styles";
import { H4 } from "../../../constants/type.js";
import Alerts from "../../notifications/index.js";

import notification from "../../../assets/interface/notification.svg";
import assets from "../../../assets/interface/assets.svg";

const Page = ({ title, children }) => {
  const [display, toggleDisplay] = useState(false);
  return (
    <Container>
      <Header>
        <H4 textTransform={"uppercase"}>{title}</H4>
        <Row>
          <Notifications
            src={notification}
            onClick={() => toggleDisplay(!display)}
          />
          <Gallery to="/gallery">
            <Notifications src={assets} />
          </Gallery>
          <Profile to="/profile" />
        </Row>
      </Header>
      {display && <Alerts />}
      <Content>{children}</Content>
    </Container>
  );
};

export default Page;
