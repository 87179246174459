import Navigation from "../../components/_layout/navigation";
import Customize from "../../components/customization";

const Onboarding = () => {
  return (
    <>
      <Navigation />
      <Customize />
    </>
  );
};

export default Onboarding;
