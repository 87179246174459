// Relative Imports
import { Container } from "./styles";

const Column = ({ children, background, ...rest }) => {
  return (
    <Container background={background} {...rest}>
      {children}
    </Container>
  );
};

export default Column;
