import styled from "styled-components";

export const Container = styled.div`
  height: 480px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${(props) => props.theme.layout.default.radius}
    ${(props) => props.theme.layout.default.radius} 0px 0px;
  background: ${(props) => props.theme.background};
  border: 1px solid ${(props) => props.theme.border};
  box-shadow: ${(props) => props.theme.layout.shadow};
`;

export const Play = styled.div`
  height: 64px;
  width: 64px;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 500ms;
  background: ${(props) => props.theme.variants.purple[3].hex};

  &:hover {
    transition: 500ms;
    cursor: pointer;
    height: 60px;
    width: 60px;
  }
`;

export const Icon = styled.img`
  height: 32px;
  width: 32px;
  margin-right: -4px;
`;
