import { useState } from "react";
import { useParams } from "react-router-dom";
import Aside from "../../components/_layout/aside";
import Page from "../../components/_layout/page";
import Responsive from "../../components/_layout/responsive";
import Task from "../../components/_quests/task";
import Grid from "../../components/_grid/grid/";
import Modal from "../../components/modal";

import { tasks } from "../../constants/taskApi.js";

const Master = () => {
  let { category } = useParams();
  const [show, showModal] = useState(false);

  const renderTasks = () => {
    return tasks.map((t, i) => {
      return (
        <Task
          key={i}
          title={t.title}
          description={t.description}
          disabled={t.accessAllowed}
          route={`/quests/${t.category}/${t.subCategory}/${t.level}/`}
        />
      );
    });
  };

  return (
    <>
      {show && <Modal onClick={() => showModal(false)} />}
      <Responsive>
        <Aside />
        <Page title={category}>
          <Grid rows={"1"}>{renderTasks()}</Grid>
        </Page>
      </Responsive>
    </>
  );
};

export default Master;
