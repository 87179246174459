// Relative Imports
import { Container } from "./styles";
import { H5, P } from "../../../constants/type.js";

const Information = ({ description }) => {
  return (
    <Container>
      <H5 mb="16px">Overview</H5>
      <P mb="20px">
        Come, come, you talk greasily; your lips grow foul. Hast thou never an
        eye in thy head? Thou clouted crook-pated hugger-mugger! Thou
        dissembling idle-headed pigeon-egg! Thou mangled fen-sucked bum-bailey!
        Thou leathern-jerkin, crystal-button, knot-pated, agatering,
        puke-stocking, caddis-garter, smooth-tongue, Spanish pouch! Thou rank
        hedge-born boar-pig! Thou wimpled clapper-clawed flax-wench! Thou
        qualling unwash'd devil-mon!
      </P>
      <P mb="20px">
        Thou surly doghearted bladder! Thou wimpled pox-marked whey-face! Thou
        qualling milk-livered measle! You are a tosser, wanker, git and mankey!
        A nutter, a lazy sod, a cream faced loon! Thou art as loathsome as a
        toad. Peace, ye fatguts! I do desire we may be better strangers. Thou
        art a flesh-monger, fool and coward. You scullion, your rampillian, you
        fustilarian. I’ll tickle your catastrophe. Thou art as fat as butter.
      </P>
      <P mb="20px">
        Come, come, you talk greasily; your lips grow foul. Hast thou never an
        eye in thy head? Thou clouted crook-pated hugger-mugger! Thou
        dissembling idle-headed pigeon-egg! Thou mangled fen-sucked bum-bailey!
        Thou leathern-jerkin, crystal-button, knot-pated, agatering,
        puke-stocking, caddis-garter, smooth-tongue, Spanish pouch! Thou rank
        hedge-born boar-pig! Thou wimpled clapper-clawed flax-wench! Thou
        qualling unwash'd devil-mon!
      </P>
      <P mb="20px">
        Thou surly doghearted bladder! Thou wimpled pox-marked whey-face! Thou
        qualling milk-livered measle! You are a tosser, wanker, git and mankey!
        A nutter, a lazy sod, a cream faced loon! Thou art as loathsome as a
        toad. Peace, ye fatguts! I do desire we may be better strangers. Thou
        art a flesh-monger, fool and coward. You scullion, your rampillian, you
        fustilarian. I’ll tickle your catastrophe. Thou art as fat as butter.
      </P>
      <P mb="20px">
        Thou spongy doghearted pigeon-egg! Thou adulterate decayed horse-drench!
        Thou base clapper-clawed boar-pig! Thou soulless tallow-faced
        clack-dish! Thou dissembling fool-born eternal devil! Thou gleeking
        fly-bitten bum-bailey! Thou jarring ill-bred no bowels! Thou creeping
        common-kissing malt-worm! Thou rank prick-eared coxcomb!
      </P>
      <P>
        Thou spongy doghearted pigeon-egg! Thou adulterate decayed horse-drench!
        Thou base clapper-clawed boar-pig! Thou soulless tallow-faced
        clack-dish! Thou dissembling fool-born eternal devil! Thou gleeking
        fly-bitten bum-bailey! Thou jarring ill-bred no bowels! Thou creeping
        common-kissing malt-worm! Thou rank prick-eared coxcomb!
      </P>
    </Container>
  );
};

export default Information;
