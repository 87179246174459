import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: ${(props) => props.theme.layout.shadow};
  box-shadow: ${(props) => props.theme.layout.shadow};
  border-radius: ${(props) => props.theme.layout.default.radius};
  padding: ${(props) => props.theme.layout.default.spacing};
  background: ${(props) => props.theme.foreground};
  border: 1px solid ${(props) => props.theme.border};
`;

export const Item = styled.div`
  justify-content: center;
  align-items: center;
  display: grid;
  grid-template-columns: 80px 1fr;
  padding: 12px 0px;
  grid-gap: ${(props) => props.theme.layout.default.spacing};

  &:hover {
    cursor: pointer;
  }
`;

export const Icon = styled.div`
  height: 60px;
  width: 100%;
  border-radius: ${(props) => props.theme.layout.default.radius};
  background: ${(props) => props.theme.variants.yellow[2].hex};
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;
