import { useState } from "react";
import Aside from "../../components/_layout/aside";
import Page from "../../components/_layout/page";
import Responsive from "../../components/_layout/responsive";
import Avatar from "../../components/avatar";
import Grid from "../../components/_grid/grid";
import Modal from "../../components/_modals/avatarModal";

import { avatars } from "../../constants/avatars.js";

const Gallery = () => {
  const [show, showModal] = useState(false);
  const [avatarData, setAvatarData] = useState(false);

  const showAvatar = (data) => {
    setAvatarData(data);
    showModal(true);
  };

  const renderAvatars = () => {
    return avatars.map((a, i) => {
      return (
        <Avatar
          onClick={() => showAvatar(a)}
          key={i}
          title={a.title}
          number={a.number}
          data={a}
        />
      );
    });
  };

  return (
    <>
      {show && (
        <Modal avatarData={avatarData} onClick={() => showModal(false)} />
      )}
      <Responsive>
        <Aside />
        <Page title="Gallery">
          <Grid rows="4">{renderAvatars()}</Grid>
        </Page>
      </Responsive>
    </>
  );
};

export default Gallery;
