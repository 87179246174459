// Relative Imports
import { Container, Play, Icon } from "./styles";

import play from "../../../assets/icons/play.svg";

const Video = ({ onClick }) => {
  return (
    <Container>
      <Play onClick={onClick}>
        <Icon src={play} />
      </Play>
    </Container>
  );
};

export default Video;
