export const proposals = [
  {
    title: "First Proposal",
    description:
      "Thou art some fool, I am loath to beat thee. Thou reeky rampallian dewberry! Methink'st thou art a general offence and every man should beat thee. Were I like thee I'd throw away myself. Thou slander of thy heavy mother's womb! Thou fusty rude-growing flirt-gill! Thou misbegotten bat-fowling maggot-pie! Thine sole name blisters our tongues. Thou churlish shard-borne harpy!",
    points: "100",
    progress: "1.0",
    category: "grants",
    subCategory: "technology",
    level: "introduction",
    route: "task-name",
    accessAllowed: true,
  },
  {
    title: "Second Proposal",
    description:
      "Thou art some fool, I am loath to beat thee. Thou reeky rampallian dewberry! Methink'st thou art a general offence and every man should beat thee. Were I like thee I'd throw away myself. Thou slander of thy heavy mother's womb! Thou fusty rude-growing flirt-gill! Thou misbegotten bat-fowling maggot-pie! Thine sole name blisters our tongues. Thou churlish shard-borne harpy!",
    points: "100",
    progress: "1.0",
    category: "grants",
    subCategory: "technology",
    level: "introduction",
    route: "task-name",
    accessAllowed: true,
  },
  {
    title: "Third Proposal",
    description:
      "Thou art some fool, I am loath to beat thee. Thou reeky rampallian dewberry! Methink'st thou art a general offence and every man should beat thee. Were I like thee I'd throw away myself. Thou slander of thy heavy mother's womb! Thou fusty rude-growing flirt-gill! Thou misbegotten bat-fowling maggot-pie! Thine sole name blisters our tongues. Thou churlish shard-borne harpy!",
    points: "100",
    progress: "1.0",
    category: "grants",
    subCategory: "technology",
    level: "introduction",
    route: "task-name",
    accessAllowed: true,
  },
];
