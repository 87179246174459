import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${(props) => props.theme.border};
  padding: ${(props) => props.theme.layout.default.padding};
  border-radius: ${(props) => props.theme.layout.default.radius};
`;
