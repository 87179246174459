import { useState } from "react";
import Chart from "react-apexcharts";

// Relative Imports
import { Container } from "./styles";
import { H5, P } from "../../../constants/type.js";
import { options } from "./chart-data";

const Goals = ({ onClick }) => {
  const [series, setSeries] = useState([32]);

  return (
    <Container>
      <Chart
        options={options}
        series={series}
        type="radialBar"
        height={"350"}
      />
      <>
        <H5 mt="-50px">Status</H5>
        <P mb="30px">Current Progress</P>
      </>
    </Container>
  );
};

export default Goals;
