// Relative Imports
import { Container, Avatar, Image } from "./styles";
import Menu from "../menu/index.js";

const Aside = () => {
  return (
    <Container>
      <Avatar>
        <Image />
      </Avatar>
      <Menu />
    </Container>
  );
};

export default Aside;
