export const options = {
  chart: {
    type: "radialBar",
  },
  series: [67],
  colors: ["#99ccf1"],
  plotOptions: {
    radialBar: {
      startAngle: -135,
      endAngle: 135,
      track: {
        background: "#f4f5f7",
        startAngle: -135,
        endAngle: 135,
      },
      dataLabels: {
        name: {
          show: false,
        },
        value: {
          fontSize: "40px",
          show: true,
          fontFamily: `'Secular One', sans-serif`,
        },
      },
    },
  },
  fill: {
    type: "horizontal",
    gradient: {
      shade: "dark",
      type: "horizontal",
      gradientToColors: ["#f64538", "#fddad7"],
      stops: [0, 50],
    },
  },
  stroke: {
    lineCap: "butt",
  },
  labels: ["Progress"],
};
