import Aside from "../../components/_layout/aside";
import Page from "../../components/_layout/page";
import Responsive from "../../components/_layout/responsive";
import Level from "../../components/_quests/level/index.js";

import Grid from "../../components/_grid/grid";
import { tasks } from "../../constants/taskApi.js";
import { H4, P } from "../../constants/type.js";

const Quests = () => {
  const renderTasks = () => {
    const grants = Object.values(tasks).filter(
      (cat) => cat.category === "grants"
    );
    return grants.map((t, i) => {
      return (
        <Level
          key={i}
          title={`${t.subCategory}`}
          description={t.description}
          disabled={t.accessAllowed}
          route={`/quests/${t.subCategory}`}
        />
      );
    });
  };

  const renderInvestments = () => {
    const investments = Object.values(tasks).filter(
      (cat) => cat.category === "investments"
    );
    return investments.map((t, i) => {
      return (
        <Level
          key={i}
          title={`${t.subCategory}`}
          description={t.description}
          disabled={t.accessAllowed}
          route={`/quests/${t.subCategory}`}
        />
      );
    });
  };
  return (
    <Responsive>
      <Aside />
      <Page title="Quests">
        <>
          <H4 mb="20px">Grants</H4>
          <P mt="-16px" mb="20px">
            Discover skill trees for Grants
          </P>
        </>
        <Grid rows={"3"}>{renderTasks()}</Grid>
        <>
          <H4 mb="20px" mt="20px">
            Investments
          </H4>
          <P mt="-16px" mb="20px">
            Discover skill trees for Investments
          </P>
        </>
        <Grid rows={"3"}>{renderInvestments()}</Grid>
      </Page>
    </Responsive>
  );
};

export default Quests;
