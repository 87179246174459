import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`
  height: 280px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: ${(props) => props.theme.layout.default.radius};
  border: 1px solid ${(props) => props.theme.border};
`;

export const Column = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  margin: auto;
`;

export const Route = styled(Link)`
  display: flex;
  flex-direction: row;
  grid-gap: ${(props) => props.theme.layout.default.spacing};
  text-decoration: none;
`;

export const Disabled = styled.div`
  display: flex;
  flex-direction: row;
  text-decoration: none;
  cursor: not-allowed;
  opacity: 55%;
  grid-gap: ${(props) => props.theme.layout.default.spacing};
  padding: ${(props) => props.theme.layout.default.spacing};
`;

export const Lock = styled.div`
  width: 24px;
  height: 24px;
  min-height: 24px;
  min-width: 24px;
  border-radius: 12px;
  background: pink;
  position: relative;
  bottom: -32px;
  right: 0px;
`;
