// Relative Imports
import {
  useDynamicContext,
  DynamicContextProvider,
  DynamicWidget,
} from "@dynamic-labs/sdk-react";
import { useState } from "react";
import { useTheme } from "styled-components";
import { useNavigate } from "react-router-dom";
// Relative Imports
import {
  Container,
  Window,
  Row,
  Button,
  Body,
  Image,
  Data,
  Header,
  Grid,
  Screen,
} from "./styles";
import { H4, P } from "../../constants/type.js";
import Column from "../_layout/column/index.js";

const Customize = ({ onClick }) => {
  let navigate = useNavigate();
  const theme = useTheme();
  const [step, updateStep] = useState(1);

  const handleStep = () => {
    if (step === 4) {
      alert(
        "Mint NFT process goes here. User is redirected afterwards to dashboard."
      );
      navigate("/overview", { replace: true });
    } else {
      updateStep(step + 1);
    }
  };

  return (
    <Container>
      <Window>
        <Body>
          <Row>
            <Image />
            <Column
              justifyContent="space-between"
              width="50%"
              background={theme.accent}
            >
              <div>
                {step === 1 && (
                  <>
                    <H4 mb="20px">Quests Portal</H4>
                    <Screen>
                      <P mb="20px">
                        This is where you begin your journey to become a
                        contributing member of the Game7.
                      </P>
                      <P mb="20px">
                        With a enough time and effort you can go from a new
                        member into a person who can make decisions for the DAO
                        including who recieves capital.
                      </P>
                      <P mb="20px">
                        We're going to teach you the most important concepts of
                        the Quests Platform, such as Quests, Levels and Points,
                        so you can hit the ground running.
                      </P>
                      <P mb="20px">To get started click the button below.</P>
                    </Screen>
                  </>
                )}
                {step === 2 && (
                  <>
                    <H4 mb="20px">Levels</H4>
                    <Screen>
                      <P mb="20px">
                        There are a number of different verticals that represent
                        the areas in which the DAO is mandated to deploy
                        capital.
                      </P>
                      <P mb="20px">
                        Within each vertical is 7 levels and within each level
                        is a certain varying amount of tasks. These tasks
                        represent the requirements needed to complete a level.
                      </P>
                      <P mb="20px">
                        As you complete each level you unlock different levels
                        of access, status, points and rewards.
                      </P>
                      <P></P>
                    </Screen>
                  </>
                )}
                {step === 3 && (
                  <>
                    <H4 mb="20px">Points</H4>
                    <Screen>
                      <P mb="20px">
                        Your XP Points represent the amount of progress you've
                        made in the Quests Portal. They're what allow you to
                        upgrade your profile, redeem for rewards, get access to
                        special events and other opportunities.
                      </P>
                      <P mb="20px">
                        You earn points for every contribution within the DAO.
                        In fact, by completing this tutorial you will have earnt
                        500 XP, allowing you to stand out from other members of
                        the community.
                      </P>
                      <P mb="20px">
                        Your points will all be accured to your profile, which
                        is a NFT, automagically so there's nothing you need to
                        do except have fun and follow the steps.
                      </P>
                    </Screen>
                  </>
                )}
                {step === 4 && (
                  <>
                    <H4 mb="20px">Profile NFT</H4>
                    <Screen>
                      <P mb="20px">
                        Your profile NFT has no value at this moment. However,
                        as you complete tasks, move through levels and make
                        contributions, it will become more valuable and provide
                        you access, power and rewards.
                      </P>
                      <P mb="20px">
                        You can also begin to customize your profile with
                        upgrades by using your points to redeem for rare
                        qualities that provide further accelaration and boosted
                        earnings.
                      </P>
                      <P mb="20px">
                        You've earned 500 rewards so you can start to customize
                        this NFT right now. To get started click the "Mint NFT"
                        button below.
                      </P>
                    </Screen>
                  </>
                )}
              </div>
              <Button onClick={() => handleStep()}>
                {step < 4 ? "Next Step" : "Mint Profile"}
              </Button>
            </Column>
          </Row>
        </Body>
      </Window>
    </Container>
  );
};

export default Customize;
