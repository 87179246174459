// Relative Imports
import { Container } from "./styles";
import { H5, P } from "../../../constants/type.js";

const Instructions = ({ description }) => {
  return (
    <Container>
      <H5 mb="16px">Overview</H5>
      <P mb="20px">
        Come, come, you talk greasily; your lips grow foul. Hast thou never an
        eye in thy head? Thou clouted crook-pated hugger-mugger! Thou
        dissembling idle-headed pigeon-egg! Thou mangled fen-sucked bum-bailey!
        Thou leathern-jerkin, crystal-button, knot-pated, agatering,
        puke-stocking, caddis-garter, smooth-tongue, Spanish pouch! Thou rank
        hedge-born boar-pig! Thou wimpled clapper-clawed flax-wench! Thou
        qualling unwash'd devil-mon!
      </P>
      <P>
        Come, come, you talk greasily; your lips grow foul. Hast thou never an
        eye in thy head? Thou clouted crook-pated hugger-mugger! Thou
        dissembling idle-headed pigeon-egg! Thou mangled fen-sucked bum-bailey!
        Thou leathern-jerkin, crystal-button, knot-pated, agatering,
        puke-stocking, caddis-garter, smooth-tongue, Spanish pouch! Thou rank
        hedge-born boar-pig! Thou wimpled clapper-clawed flax-wench! Thou
        qualling unwash'd devil-mon!
      </P>
    </Container>
  );
};

export default Instructions;
