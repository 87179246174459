// Relative Imports
import { Container, Header, Body } from "./styles";
import { H5, P } from "../../constants/type.js";

const Alerts = () => {
  return (
    <Container>
      <Header>
        <H5>Alerts</H5>
      </Header>
      <Body>
        <P>Placeholder Section</P>
      </Body>
    </Container>
  );
};

export default Alerts;
