// Relative Imports
import { Container, Image, Column } from "./styles";
import { H6, P } from "../../constants/type.js";

const Avatar = ({ key, title, number, data, onClick }) => {
  return (
    <Container key={key} onClick={() => onClick(data)}>
      <Image />
      <Column>
        <H6>{title}</H6>
        <P>{number}</P>
      </Column>
    </Container>
  );
};

export default Avatar;
