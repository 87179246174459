import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  useDynamicContext,
  DynamicAuthFlow,
  DynamicContextProvider,
} from "@dynamic-labs/sdk-react";
import Navigation from "../components/_layout/navigation";
import Hero from "../components/hero";
import Footer from "../components/footer";

const Home = () => {
  let navigate = useNavigate();
  const {
    user,
    handleLogOut,
    setShowAuthFlow,
    showAuthFlow,
  } = useDynamicContext();

  // console.log("HERO", user === undefined ? "Logged Out" : "Logged In");

  return (
    <>
      <Navigation />
      {showAuthFlow && (
        <DynamicAuthFlow
          onAuthSuccess={({ authToken, user }) => {
            navigate("/onboarding", { replace: true });
          }}
        />
      )}
      <Hero onClick={() => setShowAuthFlow(true)} />
    </>
  );
};

export default Home;

// const [isLoggedin, setLoggedin] = useState(false);
// const [address, setAddress] = useState("");
//
// useEffect(() => {
//   const authToken = _auth.getCookies();
//   if (authToken) {
//     setLoggedin(true);
//   }
//   _auth.metaMaskClientCheck();
// }, []);
//
// const auth = () => {
//   _auth.authConnect().then(() => {
//     const _a = _auth.getAddress();
//     if (_a.length > 0) {
//       setAddress(_a);
//       setLoggedin(true);
//     }
//   });
// };
//
// const logout = () => {
//   setLoggedin(false);
//   setAddress("");
//   _auth.deleteCookies();
//   _auth.metaMaskClientCheck();
// };
