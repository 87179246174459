// Relative Imports
import { Container, Item, Icon, Column } from "./styles";
import { H6, P } from "../../../constants/type.js";

const Stats = () => {
  return (
    <Container>
      <Item>
        <Icon />
        <Column>
          <H6>LEVEL</H6>
          <P>Introduction</P>
        </Column>
      </Item>
      <Item>
        <Icon />
        <Column>
          <H6>TASKS</H6>
          <P>12</P>
        </Column>
      </Item>
      <Item>
        <Icon />
        <Column>
          <H6>LENGTH</H6>
          <P>30 days</P>
        </Column>
      </Item>
    </Container>
  );
};

export default Stats;
