import styled from "styled-components";
import media from "../../constants/media.js";
import { Link } from "react-router-dom";

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f9f9f9;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: bottom;
`;

export const Align = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Warning = styled.div`
  font-size: 15px;
  color: ${(props) => props.theme.secondary};
  margin-top: 16px;
  font-family: "Inter", sans-serif;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;

  ${media.md`
    justify-content: flex-start;
  `};
`;

export const Section = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  grid-gap: 24px;
  margin-top: 20px;
  z-index: 2000;
  ${media.md`
    justify-content: center;
  `}
`;

export const Fill = styled.button`
  height: 60px;
  width: 220px;
  display: flex;
  font-family: "Inter", sans-serif;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: white;
  background: ${(props) => props.theme.variants.accent[10].hex};
  border-radius: 8px;
  text-decoration: none;
  transition: 750ms;
  text-decoration: none;
  outline: none;
  border: none;

  ${media.md`
      width: 200px;
  `}

  &:hover {
    transition: 750ms;
    cursor: pointer;
  }
`;
