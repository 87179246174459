import styled, { keyframes } from "styled-components";
const scale = keyframes`
  0% { -webkit-transform: translateY(30px);  }
  50% { -webkit-transform: translateY(0px);  }
  100% { -webkit-transform: translateY(36px);  }
`;

const display = keyframes`
  0% { opacity: 25% }
  50% { opacity: 75%  }
  100% { opacity: 100% }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  margin-bottom: 20px;
`;

export const Label = styled.label`
  font-size: 13px;
  font-family: "Inter", sans-serif;
  color: ${(props) => props.theme.secondary};
`;

export const Error = styled.label`
  font-size: 13px;
  font-family: "Inter", sans-serif;
  color: ${(props) => props.theme.accent};
  animation: ${scale} 1s ease-out;
  margin-right: 40px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Cell = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
  align-items: center;
  border-radius: 4px;
  transition: 750ms;
  border: 1px solid
    ${(props) =>
      props.active
        ? `${props.theme.variants.grey[4].hex}`
        : `${props.theme.border}`};
`;

export const Point = styled.div`
  font-size: 16px;
  text-transform: uppercase;
`;

export const Button = styled.button`
  padding: 8px 12px;
  color: white;
  font-size: 12px;
  border-radius: 4px;
  border: none;
  margin-left: 20px;
  height: max-content;
  text-align: center;
  background: ${(props) => props.theme.accent};
  transition: 750ms;
  animation: ${display} 1s ease-in;

  &:hover {
    cursor: pointer;
    transition: 750ms;
  }
`;

export const Field = styled.input`
  padding: 20px;
  width: 100%;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  outline: none;
  color: ${(props) => props.theme.primary};
  border: none;
  background: none;

  &::placeholder {
    color: ${(props) => props.theme.secondary};
  }
`;
