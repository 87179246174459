import styled from "styled-components";
import media from "./media.js";

export const Title = styled.div`
  line-height: ${(props) => props.lineHeight};
  font-family: ${(props) => props.theme.layout.fontHeader};
  font-size: ${(props) => props.fontSize};
  color: ${(props) =>
    props.color === "primary" ? props.theme.primary : props.theme.secondary};
  margin-top: ${(props) => props.mt};
  margin-bottom: ${(props) => props.mb};
  margin-left: ${(props) => props.ml};
  margin-right: ${(props) => props.mr};
  text-transform: ${(props) =>
    props.textTransform ? props.textTransform : "uppercase"};
  font-weight: ${(props) => props.fontWeight};
`;

export const Custom = styled.div`
  line-height: ${(props) => props.lineHeight};
  font-family: ${(props) =>
    props === "header"
      ? props.theme.layout.fontHeader
      : props.theme.layout.fontBody};
  font-size: ${(props) => props.fontSize};
  color: ${(props) =>
    props.color === "primary" ? props.theme.primary : props.theme.secondary};
  margin-top: ${(props) => props.mt};
  margin-bottom: ${(props) => props.mb};
  margin-left: ${(props) => props.ml};
  margin-right: ${(props) => props.mr};
  text-transform: ${(props) =>
    props.textTransform ? props.textTransform : "uppercase"};
  font-weight: ${(props) => props.fontWeight};
`;

export const H1 = styled.h1`
  font-family: ${(props) => props.theme.layout.fontHeader};
  font-size: 60px;
  color: ${(props) => props.theme.primary};
  line-height: 1.1em;
  margin: 0px;
  text-transform: ${(props) =>
    props.textTransform ? props.textTransform : "uppercase"};
  margin-top: ${(props) => props.mt};
  margin-bottom: ${(props) => props.mb};
  margin-left: ${(props) => props.ml};
  margin-right: ${(props) => props.mr};
`;

export const H2 = styled.h2`
  font-family: ${(props) => props.theme.layout.fontHeader};
  font-size: 50px;
  color: ${(props) => props.theme.primary};
  line-height: 1.1em;
  margin: 0px;
  text-transform: ${(props) =>
    props.textTransform ? props.textTransform : "uppercase"};
  margin-top: ${(props) => props.mt};
  margin-bottom: ${(props) => props.mb};
  margin-left: ${(props) => props.ml};
  margin-right: ${(props) => props.mr};
`;

export const H3 = styled.h3`
  font-family: ${(props) => props.theme.layout.fontHeader};
  font-size: 40px;
  color: ${(props) => props.theme.primary};
  line-height: 1.1em;
  margin: 0px;
  text-transform: ${(props) =>
    props.textTransform ? props.textTransform : "uppercase"};
  margin-top: ${(props) => props.mt};
  margin-bottom: ${(props) => props.mb};
  margin-left: ${(props) => props.ml};
  margin-right: ${(props) => props.mr};
`;

export const H4 = styled.h4`
  f  font-family: ${(props) => props.theme.layout.fontHeader};
  font-size: 30px;
  color: ${(props) => props.theme.primary};
  line-height: 1.1em;
  margin: 0px;
  text-transform: ${(props) =>
    props.textTransform ? props.textTransform : "uppercase"};
  margin-top: ${(props) => props.mt};
  margin-bottom: ${(props) => props.mb};
  margin-left: ${(props) => props.ml};
  margin-right: ${(props) => props.mr};
`;

export const H5 = styled.h5`
  font-family: ${(props) => props.theme.layout.fontHeader};
  font-size: 20px;
  color: ${(props) => props.theme.primary};
  line-height: 1.1em;
  margin: 0px;
  text-transform: ${(props) =>
    props.textTransform ? props.textTransform : "uppercase"};
  margin-top: ${(props) => props.mt};
  margin-bottom: ${(props) => props.mb};
  margin-left: ${(props) => props.ml};
  margin-right: ${(props) => props.mr};
`;

export const H6 = styled.h6`
  font-family: ${(props) => props.theme.layout.fontHeader};
  font-size: 16px;
  color: ${(props) => props.theme.primary};
  line-height: 1.1em;
  margin: 0px;
  text-transform: ${(props) =>
    props.textTransform ? props.textTransform : "uppercase"};
  margin-top: ${(props) => props.mt};
  margin-bottom: ${(props) => props.mb};
  margin-left: ${(props) => props.ml};
  margin-right: ${(props) => props.mr};
`;

export const P = styled.p`
  font-size: 16px;
  line-height: 1.5em;
  font-family: ${(props) => props.theme.layout.fontBody};
  color: ${(props) => props.theme.secondary};
  margin: 0px;
  text-align: ${(props) => props.textAlign};
  margin-top: ${(props) => props.mt};
  margin-bottom: ${(props) => props.mb};
  margin-left: ${(props) => props.ml};
  margin-right: ${(props) => props.mr};
  color: ${(props) =>
    props.color === "primary" ? props.theme.primary : props.theme.secondary};
`;

// export const Item = styled.p`
//   font-family: ${(props) => props.theme.layout.fontBody};
//   font-size: 16px;
//   color: ${(props) => props.theme.primary};
//   line-height: 1.5em;
//   margin: 0px;
//   margin-top: ${(props) => props.mt};
//   margin-bottom: ${(props) => props.mb};
//   margin-left: ${(props) => props.ml};
//   margin-right: ${(props) => props.mr};
//
//   ${media.md`
//     font-size: 12px;
//   `};
// `;

// export const Title = styled.div`
//   font-family: "Bebas Neue", sans-serif;
//   font-size: 100px;
//   color: ${(props) => props.theme.primary};
//   line-height: 1.1em;
//   max-width: ${(props) => props.maxWidth};
//   margin: 0px;
// `;
//
// export const Description = styled.p`
//   font-family: "Inter", sans-serif;
//   font-size: 18px;
//   line-height: 1.5em;
//   color: ${(props) => props.theme.secondary};
//   max-width: ${(props) => props.maxWidth};
//   margin: 0px;
// `;
