import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled(Link)`
  text-decoration: none;
  padding: ${(props) => props.theme.layout.default.padding};
  border-radius: ${(props) => props.theme.layout.default.radius};
  background: ${(props) => props.theme.foreground};
  border: 1px solid ${(props) => props.theme.border};
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 12px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  grid-gap: 12px;
`;

export const Pill = styled.div`
  padding: 8px 12px;
  border-radius: 24px;
  background: ${(props) => props.theme.variants.purple[1].hex};
  border: 1px solid ${(props) => props.theme.variants.purple[2].hex};
`;
